import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {
  Menu,
  NavMode,
  Profile,
} from '@nymos/theme/sdk';

@Component({
  selector: 'thm-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent implements OnInit {

  @HostBinding('class')
  private classes: string = 'thm-layout thm-app-layout';

  @HostBinding('class.folded')
  private get folded(): boolean { return this.mode === NavMode.Fold; }

  @Input()
  public mode: NavMode;

  @Input()
  public opened: boolean;

  @Input()
  public menu: Menu;

  @Input()
  public profile: Profile;

  @Output()
  public modeChange: EventEmitter<NavMode> = new EventEmitter();

  @Output()
  public openedChange: EventEmitter<boolean> = new EventEmitter();

  @Output('action') // tslint:disable-line:no-output-rename
  public actionEmitter: EventEmitter<any> = new EventEmitter();

  public get sidemode(): NavMode {
    return this.mode !== NavMode.Fold ? this.mode : NavMode.Side;
  }

  constructor() { }

  public ngOnInit(): void {}

  public onAction(action: any): void {
    this.actionEmitter.emit(action);
  }

}
